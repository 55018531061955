<template>
  <div class="container">
   
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
    

      <template #name="{ row }">
        <div>
         {{row.name}}( {{row.sex === 1 ? '男':'女'}})
        </div>
      </template>
      <template #area="{ row }">
        <div>
         {{row.area.join('-')}}
        </div>
      </template>
      <template #handle="{ row }">
        <div>
        <el-button type="success" size="small" @click="openDetails(row)">详 情</el-button>
        </div>
      </template>
    </auto-table>

    <el-dialog title="详情" :visible.sync="show" width="50%">
      <el-form v-if="cur.id">
        <el-form-item label="联系人" >
           {{cur.name}}( {{cur.sex === 1 ? '男':'女'}})
        </el-form-item>
         <el-form-item label="电话" >
           {{cur.phone}}
        </el-form-item>
         <el-form-item label="申请代理区域" >
           {{cur.area.join(',')}}
        </el-form-item>
         <el-form-item label="行业" >
           {{cur.industry}}
           <p> {{cur.industry_other}}</p>
           
        </el-form-item>
         <el-form-item label="优势" >
           {{cur.adv.join(',')}}
           <p> {{cur.adv_other}}</p>
          
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from "@/components/select/coachSelect.vue";
export default {
  props: ["cooperator_id"],
  components: {
    coachSelect,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "姓名", value: "name",type:'custom'},
        { name: "联系电话", value: "phone" },
        { name: "申请代理区域", value: "area" ,type:'custom'},
        { name: "提交时间", value: "create_time" },
        { name: "操作", value: "handle" ,type:'custom'},
      ],
      Total: 0,
     show:false,
     cur:{}
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    openDetails(row){
        this.cur = row
        this.show = true
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/message/systemFeedBack/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: 4,
        },
      }).then((res) => {
        res.data.data.rows.forEach(item=>{
            let v = JSON.parse(item.content)
            item = Object.assign(item,v)
        });
         this.DataList = res.data.data.rows
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>